import React from "react"

const faqs = [
  {
    id: 1,
    question: "What forms can I create in Inspect Live?",
    answer:
      "The following forms are available to be created through Inspect Live: 1. Entry / Ingoing Condition Report 2. Exit / Outgoing Condition Report 3. Routine Inspection Report",
  },
  {
    id: 2,
    question: "Am I able to customise the reports for different properties?",
    answer:
      "Forms can be modified to suit each individual premise. Any rooms or items added to a form through Inspect Live will be reflected in the downloaded pdf",
  },
  {
    id: 3,
    question: "Can I sync the data from the app into REI Form Live?",
    answer:
      "Electronic signatures, or eSignatures, are a broad category of methods for signing a document. A digital signature is a type of electronic signature that uses a specific technical implementation.Digital signature providers like DocuSign follow the PKI  (Public Key Infrastructure) protocol.",
  },
  {
    id: 5,
    question: "Will the photos from the app be visible in REI Form Live?",
    answer:
      "Whenever a form has been created, edited or had photos attached it must be synced before the changes will be shown in REI Forms Live/Realworks. When an Inspect Live report is printed or shared/emailed, all photos will appear at the end of the form. Photos taken via Inspect Live will be visible in REI Forms Live/Realworks once you have Synced your forms via the Photos button.",
  },
  {
    id: 4,
    question: "What operating systems support Inspect Live?",
    answer: "iOS 9+ (iPad, iPhone). Temporarily unavailable on Android.",
  },
]

const Faq = () => {
  return (
    <div id="faqs" className="py-12 md:py-12 lg:pt-40">
      <div className="bg-base-200  text-ivoryMain">
        <div className="max-w-5xl mx-auto py-6 px-6 sm:py-2 md:px-12 lg:px-8">
          <div className="max-w-2xl lg:mx-auto lg:text-center">
            <h2 className="text-2xl md:text-4xl font-extrabold tracking-tight lg:text-5xl">
              Frequently asked questions
            </h2>
          </div>
          <div className="mt-12 sm:mt-24">
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-32 lg:gap-y-6">
              {faqs.map(faq => (
                <div key={faq.id}>
                  <dt className="font-bold text-sm md:text-xl lg:text-md ">
                    {faq.question}
                  </dt>
                  <dd className="mt-3 font-light text-xs md:text-lg leading-tight lg:text-sm ">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
