import * as React from "react"

import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-gtag-outbound"

import Logo from "../images/logos/inspect_live_logo.png"
import Hero from "../images/hero2.png"
import Apple from "../images/ios.png"
import Android from "../images/android.png"

const Header = ({ siteTitle }) => (
  <div className="hero py-4 lg:py-32 md:py-24 bg-base-200 px-8 ">
    <div className="hero-content flex-col lg:flex-row-reverse">
      <img
        src={Hero}
        alt="Inspect Live mobile interface - the best property inspection app for property managers."
        className="hidden lg:block ml-24 max-w-sm "
      />
      <div className="lg:max-w-lg textLight text-center lg:text-left">
        <img
          src={Logo}
          alt="Inspect Live - powered by leading real estate paltforms Forms Live, REI Forms Live and Realworks."
          className="h-16 md:h-32 ml-12 md:ml-44 flex items-center justify-center mb-12 lg:hidden"
        />
        <h1 className="text-2xl sm:text-5xl font-bold capitalize">
          inspection reports in under 10 min. seriously.
        </h1>
        <p className="text-lg md:text-3xl lg:text-2xl py-4 md:py-8 md:px-12 lg:px-0 lg:max-w-lg leading-tight">
          We were tired of searching for an efficient & compliant inspection
          report app. So we made one.
        </p>
        <p className="py-4 text-xs md:text-lg font-light text-center lg:text-left">
          get started today with a FREE trial*
        </p>
        <div className="flex space-x-4 md:py-8 pb-2 items-center justify-center lg:items-start lg:justify-start">
          <OutboundLink
            href="https://apps.apple.com/au/app/inspect-live/id1247713997"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Apple}
              alt="App Store logo"
              className="h-10 md:h-16 lg:h-12"
            />
          </OutboundLink>
          {/* <OutboundLink
            href="https://play.google.com/store/apps/details?id=au.com.dynamicmethods.inspectlive"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Android} alt="Google Play Store logo" className="h-10 md:h-16 lg:h-12"/>
          </OutboundLink> */}
        </div>
        <div>
          <p className="text-sm italic">
            NB: Inspect Live is temporarily unavailable on{" "}
            <span className="font-semibold">Android</span>
          </p>
        </div>
        <div className="py-4 md:py-8">
          {" "}
          <Link to="#features">
            <button className="btn hover:btn-primary bg-transparent border-primary text-blueHighlight hover:text-gray-900 rounded-full px-8">
              learn more
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default Header
